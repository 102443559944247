<template>
  <BasicLayout :initVars="initVars">
    <!-- <template v-slot:layout-mainbox-inner>
      <v-card>
        <v-card-title
          v-text="doVerify ? 'Verified Pass' : 'Verified fail'"
        ></v-card-title>
      </v-card>
    </template> -->
  </BasicLayout>
</template>

<script>
import BasicLayout from '@/layouts/basic';

export default {
  components: { BasicLayout },
  data () {
    return {
      // doVerify: false,
      initVars: {}
    };
  },
  // beforeCreate () {
  //   console.log('[Register/verify][beforeCreate] ... ');
  // },
  created () {
    // console.log('[Register/verify][created] ... ');
    // console.log(this.$route.query.vd);
    // if (this.$route.query.vd == null || !this.$route.query.vd) {
    //   this.doVerify = false;
    // } else {
    //   this.doVerify = true;
    // }
  }
  // beforeMount () {
  //   console.log('[Register/verify][beforeMount] ... ');
  // },
  // mounted () {
  //   console.log('[Register/verify][mounted] ... ');
  // },
  // beforeUpdate () {
  //   console.log('[Register/verify][beforeUpdate] ... ');
  // },
  // updated () {
  //   console.log('[Register/verify][updated] ... ');
  // }
};
</script>
<style scoped>
</style>
